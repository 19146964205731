<template>
  <a-spin :spinning="spinning">
    <ele-table :columns="columns" :table-data="tableData" :total-items="totalItems" :is-full="true" :actionsType="[]"
      :current-page="pageNumber" :hide-row-selection="true" @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange" @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange">
      <a-row :gutter="8">
        <a-col class="gutter-row" :span="4">
          <a-input v-model.trim="SearchDto.PhoneNumber" :placeholder="l('手机号')" style="width: 100%"
            allowClear></a-input>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-button-group>
            <a-button type="primary" @click="search" v-if="isGranted('Pages.LGModul.SmsUsage.Query')">
              {{ l("Search") }}
            </a-button>
            <a-button @click="clearAndGetData"> {{ l("Reset") }}</a-button>
          </a-button-group>
        </a-col>
        <a-col class="gutter-row" :span="2" style="text-align: right">
          <a-button v-if="isGranted('Pages.LGModul.SmsUsage.TempCode')" @click="exportExcel">
            使用临时验证码
          </a-button>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import moment from "moment";
import EleTable from "@/components/ele-table";
import SalaryErrorDetail from "@/app/financial/detail/list";
export default {
  name: "settlementModule",
  mixins: [AppComponentBase],
  components: { EleTable, settlementDetailModule: SalaryErrorDetail },
  data: function () {
    return {
      spinning: false,
      SearchDto: {
        PhoneNumber: "",
        MaxResultCount: 50
      },
      totalItems: 0,
      pageNumber: 1,
      pagerange: [0, 1],
      totalPages: 1,
      tableData: [],
      columns: [
        {
          title: this.l("手机号"),
          dataIndex: "phoneNumber",
          align: "center",
          fixed: "left",
          width: 150,
          scopedSlots: { customRender: "phoneNumber" },
        },
        {
          title: this.l("短信类型"),
          dataIndex: "sendTypeStr",
          align: "center",
          width: 100,
          fixed: "left",
        },
        {
          title: this.l("发送时间"),
          dataIndex: "sendTime",
          width: 250,
          ellipsis: true,
          type: 'custom',
          customRender: (text, record, index) => {
            if (text) {
              var time = text.replace("T", ' ');
              return moment(time).format("YYYY-MM-DD HH:mm:ss");
            }
            return "-";
          },
        },
        {
          title: this.l("验证码"),
          dataIndex: "code",
          width: 250,
          ellipsis: true,
        },
        {
          title: this.l("过期时间"),
          dataIndex: "expiredTime",
          width: 250,
          ellipsis: true,
          type: 'custom',
          customRender: (text, record, index) => {
            if (record.sendTime) {
              return moment(record.sendTime).add(5, "minutes").format("YYYY-MM-DD HH:mm:ss");
            }
            return "-";
          },
        }
      ],
    };
  },
  methods: {
    searchData () {
      this.searchDrawerVisible = false;
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    search () {
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    getData () {
      this.spinning = true;
      let options = {
        url: this.$apiUrl + "/api/services/app/Sms/GetByPage",
        method: "POST",
        data: {
          sorting: this.SearchDto.sorting,
          skipCount: this.SearchDto.SkipCount,
          maxResultCount: this.SearchDto.MaxResultCount,
          filterText: this.SearchDto.PhoneNumber
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.totalPages = Math.ceil(
              data.totalCount / this.SearchDto.MaxResultCount
            );
            this.totalItems = data.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
              this.pageNumber * this.SearchDto.MaxResultCount,
            ];
            this.tableData = [...data.items];
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    clearFilterAndRefresh () {
      this.SearchDto.SkipCount = 0;
      this.SearchDto.sorting = "";
      this.getData();
    },
    // 排序更新data
    updateSortData (newV) {
      let { columnKey, order } = newV;
      this.SearchDto.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // ��ҳ�¼�
    showTotalFun () {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    updateShowSizeChange (newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    showSizeChange (current, size) {
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    // tableѡ���¼�
    updateSelectChange (newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // ��ҳ
    updatePageChange (newV) {
      let { page, pageSize, skipCount } = newV;
      this.onChange(page, skipCount);
    },
    onChange (page, pageSize) {
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * pageSize;
      this.SearchDto.MaxResultCount = pageSize;
      this.getData();
    },
    clearAndGetData () {
      this.SearchDto.PhoneNumber = "";
      this.getData();
    },
    exportExcel () {
      this.spinning = true;
      let options = {
        url: this.$apiUrl + "/api/services/app/Sms/GetTempCode",
        method: "GET",
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            abp.message.success("临时验证码：" + res.data + ",请在5分钟内使用");
            return;
          } else {
            abp.message.error(res.statusText);
          }
        }).finally(() => {
          this.spinning = false;
        });
    }
  },
  mounted () {
    this.getData();
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
</style>
